<script>
import {useI18n} from "vue-i18n";
import {useToast} from "vue-toastification";

export default {
    name: "Validate",

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    data() {
        return {
            cep: null,
            name: null,
            msgCep: "",
            invalid: [],
            msgError: "",
            msgName: "",
            msgNaturalness: "",
            msgFather_name: "",
            msgOwnerTaxpayer: "",
            msgEmail: "",
            msgBirthday: "",
            msgMother_name: "",
            msgPaidAt: "",
            msgLastName: "",
            msgOwnerEmail: "",
            msgOwnerBirthday: "",
            msgTitle: "",
            msgNationality: "",
            msgInstruct: "",
            msgSex: "",
            isBirthdayInvalid: false,
            msgDispatchingAgency: "",
            isNaturalnessInvalid: false,
            isDispatchingAgencyInvalid: false,
            invalid_front_confrontation: false,
            isMotherNameInvalid: false,
            isCreciInvalid: false,
            isInstructInvalid: false,
            isDescriptionInvalid: false,
            msgDescription: "",
            isFatherNameInvalid: false,
            msgDispatchDate: "",
            isFirstNameInvalid: false,
            msgTaxpayer: "",
            msgPassword: "",
            isNameInvalid: false,
            isLastNameInvalid: false,
            isOwnerBirthdayInvalid: false,
            isNameBoletoInvalid: false,
            isMccInvalid: false,
            isBankInvalid: false,
            isAgencyInvalid: false,
            isAgencyDigitInvalid: false,
            isAccountNumberInvalid: false,
            isAccountTypeInvalid: false,
            isAccountNameInvalid: false,
            isNationalityInvalid: false,
            isTitleInvalid: false,
            finalAbaDisable: null,
            secondAbaDisable: null,
            msgFirstName: "",
            isTaxpayerInvalid: false,
            isOwnerTaxpayerInvalid: false,
            msgPhone: "GENERAL.MSG.REQUIRED_FIELD",
        };
    },

    methods: {
        nameValidate(name) {
            if (!name) {
                this.isNameInvalid = true;
                this.invalid.push(this.isNameInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("name").classList.add("is-invalid");
            }
            else if (name.length < 2) {
                this.isNameInvalid = true;
                this.invalid.push(this.isNameInvalid);
                this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
                document.getElementById("name").classList.add("is-invalid");
            } else if (name.length > 255) {
                this.isNameInvalid = true;
                this.invalid.push(this.isNameInvalid);
                this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("name").classList.add("is-invalid");
            } else {
                this.isNameInvalid = false;
                document.getElementById("name").classList.add("is-valid");
            }
        },

        instructValidate(body) {
            if (body.length > 255) {
                this.isInstructInvalid = true;
                this.invalid.push(this.isInstructInvalid);
                this.msgInstruct = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("instructy_body").classList.add("is-invalid");
            } else {
                this.isInstructInvalid = false;
                document.getElementById("instructy_body").classList.add("is-valid");
            }
        },

        titleValidate(title) {
            if (!title) {
                this.isTitleInvalid = true;
                this.invalid.push(this.isTitleInvalid);
                this.msgTitle = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("title").classList.add("is-invalid");
            } else if (title.length > 255) {
                this.isTitleInvalid = true;
                this.invalid.push(this.isTitleInvalid);
                this.msgTitle = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("title").classList.remove("is-valid");
                document.getElementById("title").classList.add("is-invalid");
            } else {
                this.isTitleInvalid = false;
                document.getElementById('title').classList.remove('is-invalid')
                document.getElementById("title").classList.add("is-valid");
            }
        },

        lastNameValidate(name) {
            if (!name) {
                this.isLastNameInvalid = true;
                this.invalid.push(this.isLastNameInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgLastName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("last_name").classList.add("is-invalid");
            } else if (name.length < 3) {
                this.isLastNameInvalid = true;
                this.invalid.push(this.isLastNameInvalid);
                this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
                this.msgLastName = "GENERAL.MSG.MINIMUM_CHARACTER";
                document.getElementById("last_name").classList.add("is-invalid");
            } else if (name.length > 255) {
                this.isLastNameInvalid = true;
                this.invalid.push(this.isLastNameInvalid);
                this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
                this.msgLastName = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("last_name").classList.add("is-invalid");
            } else {
                this.isLastNameInvalid = false;
                document.getElementById("last_name").classList.add("is-valid");
            }
        },

        firstNameValidate(name) {
            if (!name) {
                this.isFirstNameInvalid = true;
                this.invalid.push(this.isFirstNameInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgFirstName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("first_name").classList.add("is-invalid");
            } else if (name.length < 3) {
                this.isFirstNameInvalid = true;
                this.invalid.push(this.isFirstNameInvalid);
                this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
                this.msgFirstName = "GENERAL.MSG.MINIMUM_CHARACTER";
                document.getElementById("first_name").classList.add("is-invalid");
            } else if (name.length > 255) {
                this.isFirstNameInvalid = true;
                this.invalid.push(this.isFirstNameInvalid);
                this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
                this.msgFirstName = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("first_name").classList.add("is-invalid");
            } else {
                this.isFirstNameInvalid = false;
                document.getElementById("first_name").classList.add("is-valid");
            }
        },

        mccValidate(mcc) {
            if (!mcc) {
                this.isMccInvalid = true;
                this.invalid.push(this.isMccInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
            } else {
                this.isMccInvalid = false;
            }
        },

        validateBank(bank) {
            if (!bank) {
                this.isBankInvalid = true;
                this.invalid.push(this.isBankInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
            } else {
                this.isBankInvalid = false;
            }
        },

        validateAgency(agency) {
            if (!agency) {
                this.isAgencyInvalid = true;
                this.invalid.push(this.isAgencyInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById('agency').classList.add("is-invalid");
            } else {
                document.getElementById("agency").classList.remove("is-invalid");
                document.getElementById('agency').classList.add("is-valid");
                this.isAgencyInvalid = false;
            }
        },

        validateAccountNumber(number) {
            if (!number) {
                this.isAccountNumberInvalid = true;
                this.invalid.push(this.isAccountNumberInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById('account_number').classList.add("is-invalid");
            } else {
                this.isAccountNumberInvalid = false;
            }
        },

        validateAccountName(name) {
            if (!name) {
                this.isAccountNameInvalid = true;
                this.invalid.push(this.isAccountNameInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById('account_name').classList.add("is-invalid");
            } else {
                this.isAccountNameInvalid = false;
            }
        },

        validateAccountType(type) {
            if (!type) {
                this.isAccountTypeInvalid = true;
                this.invalid.push(this.isAccountTypeInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById('account_type').classList.add("is-invalid");
            } else {
                this.isAccountTypeInvalid = false;
            }
        },

        nameBoletoValidate(boletoName) {
            if (!boletoName) {
                this.isNameBoletoInvalid = true;
                this.invalid.push(this.isNameBoletoInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                this.msgName = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("boleto_name").classList.add("is-invalid");
            } else if (boletoName.length < 3) {
                this.isNameBoletoInvalid = true;
                this.invalid.push(this.isNameBoletoInvalid);
                this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
                document.getElementById("boleto_name").classList.add("is-invalid");
            } else if (boletoName.length > 255) {
                this.isNameBoletoInvalid = true;
                this.invalid.push(this.isNameBoletoInvalid);
                this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("boleto_name").classList.add("is-invalid");
            } else {
                this.isNameBoletoInvalid = false;
                document.getElementById("boleto_name").classList.add("is-valid");
            }
        },

        corporateNameValidate(name) {
            if (!name) {
                this.isNameInvalid = true;
                this.invalid.push(this.isNameInvalid);
                this.msgError = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("corporate_name").classList.add("is-invalid");
            } else if (name.length < 3) {
                this.isNameInvalid = true;
                this.invalid.push(this.isNameInvalid);
                this.msgError = "GENERAL.MSG.MINIMUM_CHARACTER";
                document.getElementById("corporate_name").classList.add("is-invalid");
            } else if (name.length > 255) {
                this.isNameInvalid = true;
                this.invalid.push(this.isNameInvalid);
                this.msgError = "GENERAL.MSG.MAXIMUM_CHARACTER";
                document.getElementById("corporate_name").classList.add("is-invalid");
            } else {
                this.isNameInvalid = false;
                document.getElementById("corporate_name").classList.add("is-valid");
            }
        },

        inputName(name) {
            this.isNameInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("name").classList.remove("is-valid");
            document.getElementById("name").classList.remove("is-invalid");
            this.nameValidate(name);
        },

        inputInstruct(body) {
            this.isInstructInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("instructy_body").classList.remove("is-valid");
            document.getElementById("instructy_body").classList.remove("is-invalid");
            this.instructValidate(body);
        },

        inputTitle(title) {
            this.isTitleInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("title").classList.remove("is-valid");
            document.getElementById("title").classList.remove("is-invalid");
            this.titleValidate(title);
        },

        inputFirstName(name) {
            this.isFirstNameInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("first_name").classList.remove("is-valid");
            document.getElementById("first_name").classList.remove("is-invalid");
            this.firstNameValidate(name);
        },

        inputBoletoName(name) {
            this.isNameBoletoInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("boleto_name").classList.remove("is-valid");
            document.getElementById("boleto_name").classList.remove("is-invalid");
            this.nameBoletoValidate(name);
        },

        inputLastName(name) {
            this.isLastNameInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("last_name").classList.remove("is-valid");
            document.getElementById("last_name").classList.remove("is-invalid");
            this.lastNameValidate(name);
        },

        inputCorporateName(name) {
            this.isNameInvalid = false;
            this.finalAbaDisable = true;
            this.secondAbaDisable = true;
            document.getElementById("corporate_name").classList.remove("is-valid");
            document.getElementById("corporate_name").classList.remove("is-invalid");
            this.corporateNameValidate(name);
        },

        inputTaxpayer(taxpayer) {
            this.isTaxpayerInvalid = false;
            document.getElementById("taxpayer").classList.remove("is-valid");
            document.getElementById("taxpayer").classList.remove("is-invalid");
            this.validateTaxpayer(taxpayer);
        },

        inputOwnerTaxpayer(taxpayer) {
            this.isOwnerTaxpayerInvalid = false;
            document.getElementById("owner_taxpayer").classList.remove("is-valid");
            document.getElementById("owner_taxpayer").classList.remove("is-invalid");
            this.validateOwnerTaxpayer(taxpayer);
        },

        inputCreci(creci) {
            this.isCreciInvalid = false;
            document.getElementById("creci").classList.remove("is-valid");
            document.getElementById("creci").classList.remove("is-invalid");
            this.validateCreci(creci);
        },

        inputCep(cep) {
            document.getElementById("cep").classList.remove("is-valid");
            document.getElementById("cep").classList.remove("is-invalid");

            this.validateCep(cep);
        },

        validateCep(cep) {
            if (!cep) {
                this.invalid.push(true);
                this.msgCep = this.t('GENERAL.MSG.REQUIRED_FIELD');
                document.getElementById("cep").classList.add("is-invalid");
                document.getElementById("invalidCep").style.display = "block";
            } else if (cep.length != 9) {
                this.invalid.push(true);
                this.msgCep = this.t('GENERAL.MSG.CEP_INVALID');
                document.getElementById("cep").classList.add("is-invalid");
                document.getElementById("invalidCep").style.display = "block";
            } else {
                document.getElementById("cep").classList.add("is-valid");
                document.getElementById("invalidCep").style.display = "none";
            }
        },

        validateDispatchingAgency(dispatchingAgency, required = false) {
            if (dispatchingAgency.length > 15 || (required && !dispatchingAgency)) {
                this.isDispatchingAgencyInvalid = true;
                this.invalid.push(true);
                this.msgDispatchingAgency = this.t('GENERAL.MSG.DISPATCHING_AGENCY_INVALID');
                document.getElementById('dispatching_agency').classList.add("is-invalid");
                document.getElementById('invalidDispatching_agency').style.display = "block";
            } else if (dispatchingAgency.length < 15) {
                if (dispatchingAgency.length === 0) {
                    document.getElementById("invalidDispatching_agency").style.display = "none";
                } else {
                    this.isDispatchingAgencyInvalid = false;
                    document.getElementById("dispatching_agency").classList.add("is-valid");
                    document.getElementById("invalidDispatching_agency").style.display = "none";
                }
            }
        },

        validateNationality(nationality) {
            if (!nationality) {
                this.isNationalityInvalid = true;
                this.invalid.push(true);
                this.msgNationality = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById('nationality').classList.add("is-invalid");
                document.getElementById('invalidNationality').style.display = "block";
            } else {
                this.isNationalityInvalid = false;
                document.getElementById("nationality").classList.add("is-valid");
                document.getElementById("invalidNationality").style.display = "none";
            }
        },

        validateNaturalness(naturalness) {
            if (!naturalness) {
                this.isNaturalnessInvalid = true;
                this.invalid.push(true);
                this.msgNaturalness = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById('naturalness').classList.add("is-invalid");
                document.getElementById('invalidNaturalness').style.display = "block";
            } else {
                this.isNaturalnessInvalid = false;
                document.getElementById("naturalness").classList.add("is-valid");
                document.getElementById("invalidNaturalness").style.display = "none";
            }
        },

        validateMotherName(motherName) {
            if (!motherName) {
                this.isMotherNameInvalid = true;
                this.invalid.push(true);
                this.msgMother_name = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById('mother_name').classList.add("is-invalid");
                document.getElementById('invalidMother_name').style.display = "block";
            } else {
                this.isMotherNameInvalid = false;
                document.getElementById("mother_name").classList.add("is-valid");
                document.getElementById("invalidMother_name").style.display = "none";
            }
        },

        validateFatherName(fatherName) {
            if (!fatherName) {
                this.isFatherNameInvalid = true;
                this.invalid.push(true);
                this.msgFather_name = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById('father_name').classList.add("is-invalid");
                document.getElementById('invalidFather_name').style.display = "block";
            } else {
                this.isFatherNameInvalid = false;
                document.getElementById("father_name").classList.add("is-valid");
                document.getElementById("invalidFather_name").style.display = "none";
            }
        },

        validateDescription(description) {
            if (description && (description.length > 200)) {
                this.isDescriptionInvalid = true;
                this.invalid.push(true);
                this.msgDescription = this.t("GENERAL.MSG.FIELD_LENGHT_MAX");
                document.getElementById('description').classList.add("is-invalid");
                document.getElementById('description').style.display = "block";
            }
        },

        validateBirthday(birthday) {
            if (!birthday) {
                this.isBirthdayInvalid = true;
                this.invalid.push(this.isBirthdayInvalid);
                this.msgBirthday = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("birthday").classList.add("is-invalid");
            } else if (birthday.length === 10) {
                this.isBirthdayInvalid = false;
                document.getElementById('birthday').classList.add("is-valid");
            } else {
                this.isBirthdayInvalid = true;
                this.invalid.push(this.isBirthdayInvalid);
                this.msgBirthday = this.t("GENERAL.MSG.DATE_INVALID");
                document.getElementById("birthday").classList.add("is-invalid");
            }
        },

        validateOwnerBirthday(birthday) {
            if (!birthday) {
                this.isOwnerBirthdayInvalid = true;
                this.invalid.push(this.isOwnerBirthdayInvalid);
                this.msgOwnerBirthday = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("owner_birthday").classList.add("is-invalid");
            } else if (birthday.length === 10) {
                this.isOwnerBirthdayInvalid = false;
                document.getElementById('owner_birthday').classList.add("is-valid");
            } else {
                this.isOwnerBirthdayInvalid = true;
                this.invalid.push(this.isOwnerBirthdayInvalid);
                this.msgOwnerBirthday = this.t("GENERAL.MSG.DATE_INVALID");
                document.getElementById("owner_birthday").classList.add("is-invalid");
            }
        },

        validateFoundationDay(foundationDay) {
            if (foundationDay) {
                if (foundationDay.length === 10) {
                    document.getElementById('foundationDay').classList.add('is-valid');
                    document.getElementById('invalidFoundationDay').style.display = 'none';
                } else {
                    this.invalid.push(true);
                    this.msgBirthday = this.t("GENERAL.MSG.DATE_INVALID");
                    document.getElementById("foundationDay").classList.add("is-invalid");
                    document.getElementById("invalidFoundationDay").style.display = 'block';
                }
            } else {
                this.client.birthday = null;
            }
        },

        validateDispatchDate(dispatchDate) {
            if (dispatchDate) {
                if (dispatchDate.length > 10) {
                    this.invalid.push(true);
                    this.msgDispatchDate = this.t('GENERAL.MSG.DATE_INVALID');
                    document.getElementById('dispatch_date').classList.add("is-invalid");
                    document.getElementById('invalidDispatch_date').style.display = "block";
                } else {
                    document.getElementById("dispatch_date").classList.add("is-valid");
                    document.getElementById("invalidDispatch_date").style.display = "none";
                }
            }
        },

        validateTaxpayer(taxpayer) {
            if (!taxpayer) {
                this.isTaxpayerInvalid = true;
                this.invalid.push(this.isTaxpayerInvalid);
                this.msgTaxpayer = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("taxpayer").classList.add("is-invalid");
                document.getElementById("taxpayer").classList.remove('is-valid');
            } else if (taxpayer.length == 14 || taxpayer.length == 18) {
                if (this.validateCNPJ(taxpayer) || this.validateCpf(taxpayer)) {
                    this.isTaxpayerInvalid = false;
                    document.getElementById("taxpayer").classList.add("is-valid");
                } else {
                    this.isTaxpayerInvalid = true;
                    this.invalid.push(this.isTaxpayerInvalid);
                    this.msgTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
                    document.getElementById("taxpayer").classList.add("is-invalid");
                }
            } else {
                this.isTaxpayerInvalid = true;
                this.invalid.push(this.isTaxpayerInvalid);
                this.msgTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
                document.getElementById("taxpayer").classList.add("is-invalid");
            }
        },

        validateOwnerTaxpayer(taxpayer) {
            if (!taxpayer) {
                this.isOwnerTaxpayerInvalid = true;
                this.invalid.push(this.isOwnerTaxpayerInvalid);
                this.msgOwnerTaxpayer = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("owner_taxpayer").classList.add("is-invalid");
                document.getElementById("owner_taxpayer").classList.remove('is-valid');
            } else if (taxpayer.length == 14 || taxpayer.length == 18) {
                if (this.validateCNPJ(taxpayer) || this.validateCpf(taxpayer)) {
                    this.isOwnerTaxpayerInvalid = false;
                    document.getElementById("owner_taxpayer").classList.add("is-valid");
                } else {
                    this.isOwnerTaxpayerInvalid = true;
                    this.invalid.push(this.isOwnerTaxpayerInvalid);
                    this.msgOwnerTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
                    document.getElementById("owner_taxpayer").classList.add("is-invalid");
                }
            } else {
                this.isOwnerTaxpayerInvalid = true;
                this.invalid.push(this.isOwnerTaxpayerInvalid);
                this.msgOwnerTaxpayer = "GENERAL.MSG.TAXPAYER_INVALID";
                document.getElementById("owner_taxpayer").classList.add("is-invalid");
            }
        },

        validateCreci(creci, required = false) {
            if (!creci && required) {
                this.isCreciInvalid = true;
                this.invalid.push(this.isCreciInvalid);
                this.msgCreci = "GENERAL.MSG.REQUIRED_FIELD";
                document.getElementById("creci").classList.add("is-invalid");
                document.getElementById("creci").classList.remove('is-valid');
            }
        },

        validateGeneric(input, id, isInput = true) {
            if (!input) {
                this.invalid.push(true);
                document.getElementById("invalid" + id).style.display = "block";
                if (isInput) {
                    document.getElementById(id).classList.add("is-invalid");
                }
            } else {
                if (isInput) {
                    document.getElementById(id).classList.add("is-valid");
                }
                document.getElementById("invalid" + id).style.display = "none";
            }
        },

        selectConfigEmission(val) {
            if (!val) {
                this.invalid.push(true);
                document.getElementById('single_mov_config_emission').classList.remove('is-valid');
                document.getElementById('single_mov_config_emission').classList.add('is-invalid');
            } else {
                document.getElementById('single_mov_config_emission').classList.remove('is-invalid');
                document.getElementById('single_mov_config_emission').classList.add('is-valid');
            }
        },

        selectPaymentMethod(payMethod) {
            document.getElementById('inform_payment_method').classList.remove('is-valid', 'is-invalid');
            this.inputPayMethod(payMethod)
        },

        inputPayMethod(payMethod) {
            if (!payMethod) {
                this.invalid.push(true);
                document.getElementById('inform_payment_method').classList.add('is-invalid');
            }
        },

        validatePaidAt(paidAt) {
            if (paidAt) {
                let explode = paidAt.split('-')
                if (explode[0].length !== 4) {
                    document.getElementById('paid_at').classList.remove('is-valid');
                    document.getElementById('paid_at').classList.add('is-invalid');
                    return true;
                } else if (explode[1].length !== 2) {
                    document.getElementById('paid_at').classList.remove('is-valid');
                    document.getElementById('paid_at').classList.add('is-invalid');
                    return true;
                } else if (explode[2].length !== 2) {
                    document.getElementById('paid_at').classList.remove('is-valid');
                    document.getElementById('paid_at').classList.add('is-invalid');
                    return true;
                } else {
                    document.getElementById('paid_at').classList.remove('is-invalid');
                    document.getElementById('paid_at').classList.add('is-valid');
                    return false;
                }
            } else {
                this.invalid.push(true);
                document.getElementById("paid_at").classList.remove("is-valid");
                document.getElementById('paid_at').classList.add("is-invalid");
            }
        },

        inputPaidAt(paidAt) {
            document.getElementById("paid_at").classList.remove("is-valid");
            document.getElementById("paid_at").classList.remove("is-invalid");

            this.validatePaidAt(paidAt);
        },

        inputValuePayment(value) {
            document.getElementById('inform_payment_value').classList.remove('is-valid', 'is-invalid');
            this.validateValuePayment(value);
        },

        validateValuePayment(value) {
            if (this.formatterValue(value) <= 0) {
                this.invalid.push(true);
                document.getElementById('inform_payment_value').classList.add('is-invalid');
            } else {
                document.getElementById('inform_payment_value').classList.add('is-valid');
            }
        },

        formatterValue(value) {
            value = value.replace('R$ ', '');
            value = value.replaceAll(".", "");
            value = value.replaceAll(",", ".");
            value = parseFloat(value);
            return value;
        },

        inputGeneric(input, id) {
            document.getElementById(id).classList.remove("is-valid");
            document.getElementById(id).classList.remove("is-invalid");
            document.getElementById("invalid" + id).style.display = "none";
            this.validateGeneric(input, id);
        },

        maxCharacters(value, max, id) {
            if (value) {
                if (value.length) {
                    if (value.length > max) {
                        document.getElementById(id).classList.add("is-invalid");
                        document.getElementById('invalid_' + id).style.display = "block";
                        this.invalid.push(true);
                    } else {
                        document.getElementById('invalid_' + id).style.display = "none";
                        document.getElementById(id).classList.remove('is-invalid')
                    }
                }
            } else {
                document.getElementById('invalid_' + id).style.display = "none";
                document.getElementById(id).classList.remove('is-invalid')
            }

        },

        selectGeneric(select, id) {
            document.getElementById("invalid" + id).style.display = "none";
            this.validateGeneric(select, id, false);
        },

        validatePhone(input, id) {
            if (input && (input.length == 14 || input.length == 15)) {
                document.getElementById(id).classList.add("is-valid");
                document.getElementById("invalid" + id).style.display = "none";
            } else {
                this.invalid.push(true);
                document.getElementById("invalid" + id).style.display = "block";
                document.getElementById(id).classList.add("is-invalid");
                this.msgPhone = !input
                    ? "GENERAL.MSG.REQUIRED_FIELD"
                    : "GENERAL.MSG.PHONE_INVALID";
            }
        },

        inputPhone(input, id) {
            document.getElementById(id).classList.remove("is-valid");
            document.getElementById(id).classList.remove("is-invalid");
            document.getElementById("invalid" + id).style.display = "none";
            this.validatePhone(input, id);
        },

        validateSex(sex) {
            if (!sex) {
                this.invalid.push(true);
                this.msgSex = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById('sex').classList.add("is-invalid");
                document.getElementById("invalidSex").style.display = "block";
            } else {
                document.getElementById('sex').classList.add('is-valid');
                document.getElementById('invalidSex').style.display = "none";
            }
        },

        emailValidate(email) {
            if (!email) {
                this.invalid.push(true);
                this.msgEmail = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("email").classList.add("is-invalid");
                document.getElementById("invalidEmail").style.display = "block";
            } else if (!this.validEmail(email)) {
                this.invalid.push(true);
                this.msgEmail = this.t("GENERAL.MSG.EMAIL_INVALID");
                document.getElementById("email").classList.add("is-invalid");
                document.getElementById("invalidEmail").style.display = "block";
            } else if (email.length > 255) {
                this.invalid.push(true);
                this.msgEmail = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
                document.getElementById("email").classList.add("is-invalid");
                document.getElementById("invalidEmail").style.display = "block";
            } else {
                document.getElementById("email").classList.add("is-valid");
                document.getElementById("invalidEmail").style.display = "none";
            }
        },

        emailOwnerValidate(email) {
            if (!email) {
                this.invalid.push(true);
                this.msgOwnerEmail = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("owner_email").classList.add("is-invalid");
                document.getElementById("invalidOwnerEmail").style.display = "block";
            } else if (!this.validEmail(email)) {
                this.invalid.push(true);
                this.msgOwnerEmail = this.t("GENERAL.MSG.EMAIL_INVALID");
                document.getElementById("owner_email").classList.add("is-invalid");
                document.getElementById("invalidOwnerEmail").style.display = "block";
            } else if (email.length > 255) {
                this.invalid.push(true);
                this.msgOwnerEmail = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
                document.getElementById("owner_email").classList.add("is-invalid");
                document.getElementById("invalidOwnerEmail").style.display = "block";
            } else {
                document.getElementById("owner_email").classList.add("is-valid");
                document.getElementById("invalidOwnerEmail").style.display = "none";
            }
        },

        emailValidateForClient(email) {
            if (!email) {
                this.invalid.push(true);
            } else if (!this.validEmail(email)) {
                this.invalid.push(true);
                this.msgEmail = this.t("GENERAL.MSG.EMAIL_INVALID");
                document.getElementById("email").classList.add("is-invalid");
                document.getElementById("invalidEmail").style.display = "block";
            } else if (email.length > 255) {
                this.invalid.push(true);
                this.msgEmail = this.t("GENERAL.MSG.MAXIMUM_CHARACTER");
                document.getElementById("email").classList.add("is-invalid");
                document.getElementById("invalidEmail").style.display = "block";
            } else {
                document.getElementById("email").classList.add("is-valid");
                document.getElementById("invalidEmail").style.display = "none";
            }
        },

        validEmail(email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        inputEmail(email) {
            document.getElementById("invalidEmail").style.display = "none";
            document.getElementById("email").classList.remove("is-valid");
            document.getElementById("email").classList.remove("is-invalid");
            this.emailValidate(email);
        },

        inputEmailForClient(email) {
            document.getElementById("invalidEmail").style.display = "none";
            document.getElementById("email").classList.remove("is-valid");
            document.getElementById("email").classList.remove("is-invalid");
            this.emailValidateForClient(email);
        },

        inputOrnewEmailForClient(email) {
            document.getElementById("invalidOwnerEmail").style.display = "none";
            document.getElementById("owner_email").classList.remove("is-valid");
            document.getElementById("owner_email").classList.remove("is-invalid");
            this.emailOwnerValidate(email);
        },

        inputNationality(nationality) {
            document.getElementById("invalidNationality").style.display = "none";
            document.getElementById("nationality").classList.remove("is-valid");
            document.getElementById("nationality").classList.remove("is-invalid");
            this.validateNationality(nationality);
        },

        inputNaturalness(naturalness) {
            document.getElementById("invalidNaturalness").style.display = "none";
            document.getElementById("naturalness").classList.remove("is-valid");
            document.getElementById("naturalness").classList.remove("is-invalid");
            this.validateNaturalness(naturalness);
        },

        inputMotherName(motherName) {
            document.getElementById("invalidMother_name").style.display = "none";
            document.getElementById("mother_name").classList.remove("is-valid");
            document.getElementById("mother_name").classList.remove("is-invalid");
            this.validateMotherName(motherName);
        },

        inputFatherName(fatherName) {
            document.getElementById("invalidFather_name").style.display = "none";
            document.getElementById("father_name").classList.remove("is-valid");
            document.getElementById("father_name").classList.remove("is-invalid");
            this.validateFatherName(fatherName);
        },

        inputDispatchingAgency(dispatchingAgency, required = false) {
            document.getElementById("invalidDispatching_agency").style.display = "none";
            document.getElementById("dispatching_agency").classList.remove("is-valid");
            document.getElementById("dispatching_agency").classList.remove("is-invalid");
            this.validateDispatchingAgency(dispatchingAgency, required);
        },

        inputDispatchDate(dispatchDate) {
            document.getElementById("invalidDispatch_date").style.display = "none";
            document.getElementById("dispatch_date").classList.remove("is-valid");
            document.getElementById("dispatch_date").classList.remove("is-invalid");
            this.validateDispatchDate(dispatchDate);
        },

        inputDescription(description) {
            document.getElementById('description').style.display = 'none';
            document.getElementById("description").classList.remove("is-valid");
            document.getElementById("description").classList.remove("is-invalid");
            this.validateDescription(description);
        },

        inputBirthday(birthday) {
            this.isBirthdayInvalid = false;
            document.getElementById("birthday").classList.remove("is-valid");
            document.getElementById("birthday").classList.remove("is-invalid");
            this.validateBirthday(birthday);
        },

        inputOwnerBirthday(birthday) {
            this.isOwnerBirthdayInvalid = false;
            document.getElementById("owner_birthday").classList.remove("is-valid");
            document.getElementById("owner_birthday").classList.remove("is-invalid");
            this.validateOwnerBirthday(birthday);
        },
        inputFoundationDay(foundationDay) {
            document.getElementById("invalidFoundationDay").style.display = "none";
            document.getElementById("foundationDay").classList.remove("is-valid");
            document.getElementById("foundationDay").classList.remove("is-invalid");
            this.validateFoundationDay(foundationDay);
        },

        inputSex(sex) {
            this.validateSex(sex);
        },

        passwordValidade(password, passwordConfirm, isSingup = true) {
            if (!password) {
                this.invalid.push(true);
                this.msgPassword = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("password").classList.add("is-invalid");
                document.getElementById("invalidPassword").style.display = "block";
            }

            if (isSingup && !passwordConfirm) {
                this.invalid.push(true);
                this.msgPassword = this.t("GENERAL.MSG.REQUIRED_FIELD");
                document.getElementById("invalidPassword").style.display = "block";
                document.getElementById("passwordConfirm").classList.add("is-invalid");
            }

            this.validateEqualityPassword(password, passwordConfirm);
        },

        validateEqualityPassword(password, passwordConfirm) {
            if (password != passwordConfirm) {
                this.invalid.push(true);
                this.msgPassword = this.t("GENERAL.MSG.DIFFERENT_PASSWORDS");
                document.getElementById("password").classList.add("is-invalid");
                document.getElementById("invalidPassword").style.display = "block";
                document.getElementById("passwordConfirm").classList.add("is-invalid");
            } else {
                this.passwordStrength(password);

                if (this.invalid.length == 0) {
                    document.getElementById("password").classList.add("is-valid");
                    document.getElementById("passwordConfirm").classList.add("is-valid");
                }
            }
        },

        inputPassword(password, passwordConfirm, isSingup = true) {
            document.getElementById("invalidPassword").style.color = "red";
            document.getElementById("password").classList.remove("is-valid");
            document.getElementById("invalidPassword").style.display = "none";
            document.getElementById("password").classList.remove("is-invalid");
            document.getElementById("passwordConfirm").classList.remove("is-valid");
            document.getElementById("passwordConfirm").classList.remove("is-invalid");
            this.passwordValidade(password, passwordConfirm, isSingup);
        },

        inputSelect(moviment, index) {
            if (!this.checkIfNull(moviment, index)) {
                document.getElementById('card' + index).classList.remove('border', 'border-dark', 'border-success');
                document.getElementById('card' + index).classList.add('border', 'border-danger');
            } else {
                document.getElementById('card' + index).classList.remove('border', 'border-dark', 'border-danger');
                document.getElementById('card' + index).classList.add('border', 'border-success');
            }
        },

        inputFirstPayment(date, key) {
            if (date) {
                let explode = date.split('-')
                if (explode[0].length !== 4) {
                    document.getElementById('first_payment' + key).classList.remove('is-valid');
                    document.getElementById('first_payment' + key).classList.add('is-invalid');
                    return true;
                } else if (explode[1].length !== 2) {
                    document.getElementById('first_payment' + key).classList.remove('is-valid');
                    document.getElementById('first_payment' + key).classList.add('is-invalid');
                    return true;
                } else if (explode[2].length !== 2) {
                    document.getElementById('first_payment' + key).classList.remove('is-valid');
                    document.getElementById('first_payment' + key).classList.add('is-invalid');
                    return true;
                } else {
                    document.getElementById('first_payment' + key).classList.remove('is-invalid');
                    document.getElementById('first_payment' + key).classList.add('is-valid');
                    return false;
                }
            }
            document.getElementById('first_payment' + key).classList.remove('is-valid');
            document.getElementById('first_payment' + key).classList.add('is-invalid');
            return true;
        },

        inputMovimentValue(movVal, index) {
            if (movVal !== 'R$ 0,00') {
                document.getElementById('value_moviments' + index).classList.remove('is-invalid');
                document.getElementById('value_moviments' + index).classList.add('is-valid');
                return false;
            } else {
                document.getElementById('value_moviments' + index).classList.remove('is-valid');
                document.getElementById('value_moviments' + index).classList.add('is-invalid');
                return true;
            }
        },

        inputMovimentQuantity(movQuan, key) {
            if (movQuan > 0 && movQuan <= 240) {
                document.getElementById('quantity_moviments' + key).classList.remove('is-invalid');
                document.getElementById('quantity_moviments' + key).classList.add('is-valid');
                return false;
            } else {
                document.getElementById('quantity_moviments' + key).classList.remove('is-valid');
                document.getElementById('quantity_moviments' + key).classList.add('is-invalid');
                return true;
            }
        },

        passwordStrength(password) {
            var numbers = /([0-9])/;
            var alphabet = /([a-zA-Z])/;
            var spCharacter = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

            if (password) {
                if (password.length < 8) {
                    this.invalid.push(true);
                    this.msgPassword = this.t("GENERAL.MSG.PASSWORD_TO_SMALL");
                    document.getElementById("password").classList.add("is-invalid");
                    document.getElementById("invalidPassword").style.display = "block";
                    document
                        .getElementById("passwordConfirm")
                        .classList.add("is-invalid");
                } else if (password.length > 20) {
                    this.invalid.push(true);
                    this.msgPassword = this.t("GENERAL.MSG.PASSWORD_TO_LONG");
                    document.getElementById("password").classList.add("is-invalid");
                    document.getElementById("invalidPassword").style.display = "block";
                    document
                        .getElementById("passwordConfirm")
                        .classList.add("is-invalid");
                } else {
                    if (
                        password.match(numbers) &&
                        password.match(alphabet) &&
                        password.match(spCharacter)
                    ) {
                        this.msgPassword = this.t("GENERAL.MSG.PASSWORD_STRONG");
                        document.getElementById("invalidPassword").style.color = "green";
                        document.getElementById("invalidPassword").style.display = "block";
                    } else if (
                        password.match(alphabet) &&
                        password.match(numbers) &&
                        !password.match(spCharacter)
                    ) {
                        this.msgPassword = this.t("GENERAL.MSG.PASSWORD_MEDIUM");
                        document.getElementById("invalidPassword").style.color = "orange";
                        document.getElementById("invalidPassword").style.display = "block";
                    } else if (
                        (password.match(alphabet) &&
                            !password.match(numbers) &&
                            !password.match(spCharacter)) ||
                        (!password.match(alphabet) &&
                            password.match(numbers) &&
                            !password.match(spCharacter))
                    ) {
                        this.invalid.push(true);
                        this.msgPassword = this.t("GENERAL.MSG.PASSWORD_WEAK");
                        document.getElementById("password").classList.add("is-invalid");
                        document.getElementById("invalidPassword").style.display = "block";
                        document
                            .getElementById("passwordConfirm")
                            .classList.add("is-invalid");
                    }
                }
            }
        },

        errorMsg(error) {
            console.log(error);
            const erro = error.response.data.validations.errors;
            if (erro.sql || !erro || erro.length === 0) {
                this.toast.error(error.response.data.validations.message, {
                    timeout: false,
                });
            } else {
                for (let column in erro) {
                    for (let msg in erro[column]) {
                        this.toast.error(erro[column][msg], {timeout: false});
                    }
                }
            }
        },

        validateMoney3(value) {
            if (value % 2 == 0) {
                return value * 100;
            } else {
                return value;
            }
        },

        validateCNPJ(cnpj) {
            cnpj = cnpj.replace(/[^\d]+/g, "");

            if (cnpj == "") return false;

            if (cnpj.length != 14) return false;

            if (
                cnpj == "00000000000000" ||
                cnpj == "11111111111111" ||
                cnpj == "22222222222222" ||
                cnpj == "33333333333333" ||
                cnpj == "44444444444444" ||
                cnpj == "55555555555555" ||
                cnpj == "66666666666666" ||
                cnpj == "77777777777777" ||
                cnpj == "88888888888888" ||
                cnpj == "99999999999999"
            )
                return false;

            let size = cnpj.length - 2;
            let numbers = cnpj.substring(0, size);
            let digits = cnpj.substring(size);
            let sum = 0;
            let pos = size - 7;

            for (var i = size; i >= 1; i--) {
                sum += numbers.charAt(size - i) * pos--;
                if (pos < 2) pos = 9;
            }

            let resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (resultado != digits.charAt(0)) return false;

            size = size + 1;
            numbers = cnpj.substring(0, size);
            sum = 0;
            pos = size - 7;

            for (i = size; i >= 1; i--) {
                sum += numbers.charAt(size - i) * pos--;

                if (pos < 2) pos = 9;
            }

            resultado = sum % 11 < 2 ? 0 : 11 - (sum % 11);

            if (resultado != digits.charAt(1)) return false;

            return true;
        },

        validateCpf(cpf) {
            return validar(cpf);
        },

        widthPhone(size = 480, smaller = '<') {
            if (smaller == '<') {
                return window.innerWidth < size;
            } else {
                return window.innerWidth > size;
            }
        }
    },
};

const validar = (cpf) => checkAll(prepare(cpf));

const notDig = (i) => ![".", "-", " "].includes(i);
const prepare = (cpf) => cpf.trim().split("").filter(notDig).map(Number);
const is11Len = (cpf) => cpf.length === 11;
const notAllEquals = (cpf) => !cpf.every((i) => cpf[0] === i);
const onlyNum = (cpf) => cpf.every((i) => !isNaN(i));

const calcDig = (limit) => (a, i, idx) => a + i * (limit + 1 - idx);
const sumDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0);
const resto11 = (sumDig) => 11 - (sumDig % 11);
const zero1011 = (resto11) => ([10, 11].includes(resto11) ? 0 : resto11);

const getDV = (cpf, limit) => zero1011(resto11(sumDig(cpf, limit)));
const verDig = (pos) => (cpf) => getDV(cpf, pos) === cpf[pos];

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)];
const checkAll = (cpf) => checks.map((f) => f(cpf)).every((r) => !!r);
</script>
